import { Box } from "@chakra-ui/react"
import {
  NavigationEntity,
  NavigationLinkEntity,
  PageEntity,
  PrintServiceEntity,
} from "@jackfruit/common"
import { useEntity } from "hooks/useEntity"

interface DynamicBreadCrumbProp {
  match: any
}

export const DynamicPagesName: React.FC<DynamicBreadCrumbProp> = ({
  match,
}: {
  match: any
}) => {
  const {
    data: { name },
  } = useEntity<PageEntity>("pages", match.params.pageId)

  return (
    <Box as="span" maxW={36} textOverflow="ellipsis" noOfLines={1}>
      {name}
    </Box>
  )
}

export const DynamicNavigationsName: React.FC<DynamicBreadCrumbProp> = ({
  match,
}: {
  match: any
}) => {
  const navigationId = +match.params.navigationId
  const hasNavigationName = Boolean(navigationId)
  const {
    data: { name },
  } = useEntity<NavigationEntity>("navigations", match.params.navigationId, {
    enabled: hasNavigationName,
  })

  return <>{hasNavigationName ? name : "New"}</>
}

export const DynamicNavigationLinksLabel: React.FC<DynamicBreadCrumbProp> = ({
  match,
}: {
  match: any
}) => {
  const navigationLinkId = +match.params.navigationLinkId
  const hasNavigationLink = Boolean(navigationLinkId)
  const {
    data: { label },
  } = useEntity<NavigationLinkEntity>(
    "navigation-links",
    match.params.navigationLinkId,
    { enabled: hasNavigationLink }
  )

  return <>{hasNavigationLink ? label : "New"}</>
}

export const DynamicPrintServicesName: React.FC<DynamicBreadCrumbProp> = ({
  match,
}: {
  match: any
}) => {
  const {
    data: { displayName },
  } = useEntity<PrintServiceEntity>(
    "print-services",
    match.params.printServiceId
  )

  return <>{displayName}</>
}
