import { Button, Flex, Text } from "@chakra-ui/react"
import { PageEntityContentStatus } from "@jackfruit/common"
import React, { forwardRef } from "react"

const colorForState = {
  new: "gray.200",
  inProgress: "blue.300",
  toReview: "purple.200",
  approved: "orange.200",
  rejected: "red.300",
  live: "green.300",
  frozen: "blue.100",
  toDelete: "black",
}

const labelForState = {
  new: "New",
  inProgress: "In Progress",
  approved: "Approved",
  frozen: "Frozen",
  toReview: "To Review",
  rejected: "Rejected",
  live: "Live",
  toDelete: "To Delete",
}

interface Props {
  state: PageEntityContentStatus
  onClick?: () => void
}

const PageContentStatusSelectorItem: React.FC<Props> = forwardRef<
  HTMLDivElement,
  Props
>(({ state, onClick = () => {} }, ref) => {
  const color = colorForState[state]
  const label = labelForState[state]

  return (
    <Flex
      as={Button}
      ref={ref}
      gap={3}
      alignItems="center"
      justifyContent="flex-start"
      role="button"
      tabIndex={0}
      onClick={onClick}
      size="sm"
      variant="ghost"
      _focus={{
        outline: "none",
      }}
      _active={{
        color: "primary.500",
        bg: "primary.100",
        borderLeftColor: "primary.500",
      }}
      _hover={{
        color: "primary.500",
        bg: "primary.100",
        borderLeftColor: "primary.500",
      }}
    >
      <Flex width={4} height={4} rounded="full" backgroundColor={color} />
      <Text fontSize="sm" fontWeight="semibold">
        {label}
      </Text>
    </Flex>
  )
})

export default PageContentStatusSelectorItem
