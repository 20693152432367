import { Box, HStack } from "@chakra-ui/react"
import React from "react"
import NavbarLink from "./NavbarLink"

interface NavbarConfigLinkItem {
  type: "link"
  isExternal?: boolean
  to: string
  label: string
  icon?: React.ReactElement
  canActivate?: boolean
}

interface NavbarConfigSeparatorItem {
  type: "separator"
}

interface NavbarConfigContentItem {
  type: "content"
  content: React.ReactNode
}

interface NavbarConfigCustomComponentItem {
  type: "component"
  component: React.ReactNode
}

export interface NavbarConfig {
  expanded?: boolean
  items: (
    | NavbarConfigLinkItem
    | NavbarConfigSeparatorItem
    | NavbarConfigContentItem
    | NavbarConfigCustomComponentItem
  )[]
}

interface Props {
  config: NavbarConfig
}

interface Props {}

const Navbar: React.FC<Props> = ({ config }) => {
  return (
    <HStack
      spacing={2}
      bg="white"
      borderWidth={1}
      p={2}
      borderRadius="md"
      minW="2xl"
      w="full"
    >
      {config.items.map((item, index) => {
        switch (item.type) {
          case "link":
            return (
              <NavbarLink
                key={item.to}
                to={item.to}
                label={item.label}
                icon={item.icon}
                canActivate={item.canActivate}
                isExternal={item.isExternal}
              />
            )
          case "separator":
            return <Box key={index} width="1px" height="25px" bg="gray.300" />
          case "content":
            return <React.Fragment key={index}>{item.content}</React.Fragment>
          case "component":
            return <React.Fragment key={index}>{item.component}</React.Fragment>
          default:
            return null
        }
      })}
    </HStack>
  )
}

export default Navbar
