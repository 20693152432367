import {
  Flex,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  useDisclosure,
} from "@chakra-ui/react"
import {
  EntityId,
  PageEntity,
  PageEntityContentStatus,
} from "@jackfruit/common"
import { useEntity } from "hooks/useEntity"
import { usePatchEntity } from "hooks/usePatchEntity"
import React from "react"
import PageContentStatusSelectorItem from "./PageContentStatusSelectorItem"

const allStates: PageEntityContentStatus[] = [
  "new",
  "inProgress",
  "toReview",
  "rejected",
  "approved",
  "live",
  "frozen",
  "toDelete",
]

interface Props {
  pageId: EntityId
}

const PageContentStatusSelector: React.FC<Props> = ({ pageId }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { data: page, isLoading } = useEntity<PageEntity>("pages", pageId)

  const { mutate: patchEntity } = usePatchEntity<PageEntity>("pages")

  if (isLoading) {
    return null
  }

  const handleUpdateContentStatus = (status: PageEntityContentStatus) => {
    patchEntity({
      id: pageId,
      contentStatus: status,
    })
    onClose()
  }

  return (
    <Popover
      placement="bottom-start"
      isLazy
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
    >
      <PopoverTrigger>
        <PageContentStatusSelectorItem state={page.contentStatus} />
      </PopoverTrigger>
      <PopoverContent>
        <PopoverBody p={2}>
          <Flex flexDirection="column" gap={2} alignItems="stretch">
            {allStates.map(state => {
              return (
                <PageContentStatusSelectorItem
                  key={state}
                  state={state}
                  onClick={() => handleUpdateContentStatus(state)}
                />
              )
            })}
          </Flex>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}

export default PageContentStatusSelector
